import React, { useState } from "react";
import Vine from "./vine.png";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
const Sidebar = ({
  heroRef,
  storyRef,
  friendsRef,
  detailsRef,
  hotelRef,
  weekendRef,
  rsvpRef,
  galleryRef,
  faqRef,
}) => {
  const handleScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  let isMobile = window.mobileCheck();
  let [navVisibility, setNavVisibility] = useState(isMobile ? false : true);

  return (
    <>
      {isMobile && (
        <div
          className="openBtn"
          onClick={() => setNavVisibility(!navVisibility)}
        >
          <FontAwesomeIcon icon={navVisibility ? faXmark : faBars} size="lg" />
        </div>
      )}
      {navVisibility && (
        <div className="sidebar">
          <div className="logo">
            <img width="90" src={Vine} />
            <span>
              Bhavik <small>&amp;</small> Sarah
            </span>
            <h6>JUNE 16, 2023</h6>
            <h6>SONOMA VALLEY, CA</h6>
          </div>
          <div className="linklist">
            <span>
              <a
                onClick={() => {
                  handleScroll(heroRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                HOME
              </a>
            </span>
            {/* <span>
              <a
                onClick={() => {
                  handleScroll(storyRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                OUR STORY
              </a>
            </span>
            <span>
              <a
                onClick={() => {
                  handleScroll(friendsRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                FRIENDS
              </a>
            </span> */}
            <span>
              <a
                onClick={() => {
                  handleScroll(detailsRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                DETAILS
              </a>
            </span>
            <span>
              <a
                onClick={() => {
                  handleScroll(hotelRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                HOTEL
              </a>
            </span>
            <span>
              <a
                onClick={() => {
                  handleScroll(rsvpRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                RSVP
              </a>
            </span>
            <span>
              <a
                onClick={() => {
                  handleScroll(weekendRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                WEEKEND ACTIVITES
              </a>
            </span>
            <span>
              <a
                onClick={() => {
                  handleScroll(faqRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                FAQs
              </a>
            </span>
            {/* <span>
              <a
                onClick={() => {
                  handleScroll(galleryRef);
                  if (isMobile) setNavVisibility(false);
                }}
              >
                GALLERY
              </a>
            </span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
