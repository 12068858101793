import { forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./FAQ.css";
import Slider from "react-slick";

const isMobile = window.mobileCheck();

const FAQ = forwardRef((props, ref) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
  };
  if (isMobile) {
    return (
      <div className="slide faq" ref={ref}>
        <span className="title" style={{ letterSpacing: 1 }}>
          Helpful Information
        </span>
        <Slider {...settings}>
          <div className="faqCtn">
            <div className="tileTitle">COVID-19</div>
            <ul>
              <li>
                Please check back for testing requirements closer to the wedding
                date
              </li>
            </ul>
            <div className="tileTitle">Travel</div>
            <ul>
              <li>
                A few popular airports near Sonoma county include: Sonoma County
                Airport (STS) ~30 minutes, San Francisco Airport (SFO) ~1 hour,
                San Jose International Airport (SJC) ~1.5 hours, Sacramento
                International Aiport (SMF) ~1.5 hours, Oakland International
                (OAK) ~2 hours.
              </li>
              <li>
                If you're flying into SFO, there is a convenient{" "}
                <a
                  href="https://sanfranciscoairport.org/rental-cars/"
                  target="_blank"
                >
                  car rental center
                </a>{" "}
                accessible from the airport via AirTrain
              </li>
            </ul>
          </div>
          <div className="faqCtn">
            <div className="tileTitle">TRANSPORTATION</div>
            <ul>
              <li>
                On our wedding day, transportation will be provided to and from
                the hotel to the venue. There are no ubers available in the
                Sonoma area, so please plan accordingly
              </li>
              <li>
                Shuttles will depart from the hotel at 3:15PM and return every
                hour beginning at 9:30PM
              </li>
            </ul>
            <div className="tileTitle">PARKING</div>
            <ul>
              <li>
                Free parking is available onsite at the wedding venue and hotel
              </li>
            </ul>
            <div className="tileTitle">DRESS CODE</div>
            <ul>
              <li>
                Dress code is black-tie optional. Guests are welcome to wear
                traditional Indian or Korean dress
              </li>
            </ul>
          </div>
        </Slider>
      </div>
    );
  } else {
    return (
      <div className="slide faq" ref={ref}>
        <span className="title" style={{ letterSpacing: 1 }}>
          Helpful Information
        </span>
        <div className="faqCtn">
          <div className="tileTitle">COVID-19</div>
          <ul>
            <li>
              Please check back for testing requirements closer to the wedding
              date
            </li>
          </ul>
          <div className="tileTitle">Travel</div>
          <ul>
            <li>
              A few popular airports near Sonoma county include: Sonoma County
              Airport (STS) ~30 minutes, San Francisco Airport (SFO) ~1 hour,
              San Jose International Airport (SJC) ~1.5 hours, Sacramento
              International Aiport (SMF) ~1.5 hours, Oakland International (OAK)
              ~2 hours.
            </li>
            <li>
              If you're flying into SFO, there is a convenient{" "}
              <a
                href="https://sanfranciscoairport.org/rental-cars/"
                target="_blank"
              >
                car rental center
              </a>{" "}
              accessible from the airport via AirTrain
            </li>
          </ul>
          <div className="tileTitle">TRANSPORTATION</div>
          <ul>
            <li>
              On our wedding day, transportation will be provided to and from
              the hotel to the venue. There are no ubers available in the Sonoma
              area, so please plan accordingly
            </li>
            <li>
              Shuttles will depart from the hotel at 3:15PM and return every
              hour beginning at 9:30PM
            </li>
          </ul>
          <div className="tileTitle">PARKING</div>
          <ul>
            <li>
              Free parking is available onsite at the wedding venue and hotel
            </li>
          </ul>
          <div className="tileTitle">DRESS CODE</div>
          <ul>
            <li>
              The dress code is black-tie optional. Guests are welcome to wear
              traditional Indian or Korean dress
            </li>
          </ul>
        </div>
      </div>
    );
  }
});

export default FAQ;
