import { forwardRef } from "react";
import Gotts from "./gotts.jpeg";
import Vsattui from "./vsattui.jpeg";
import anaba from "./anaba.jpeg";
import BoonFly from "./boonfly.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWineGlass, faUtensils } from "@fortawesome/free-solid-svg-icons";
import "./Weekend.css";

const Weekend = forwardRef((props, ref) => {
  let isMobile = window.mobileCheck();
  return (
    <div className="slide weekend" ref={ref}>
      <span className="title">Saturday Wine Tastings</span>
      <div className="container">
        <a href="https://www.anabawines.com/" target="_blank">
          <div className="timeline-block">
            <div
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${
                  isMobile ? 0.75 : 0
                })), url('${anaba}')`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="timelineImg"
            />
            <div className="timeline-content">
              <div className="timelineTitle">
                Anaba Winery, Sonoma - $50/65 per person
              </div>
              <div className="tileDate">SATURDAY, JUNE 17</div>
              <div className="tileDate">10:30AM - 12:30PM</div>
            </div>
          </div>
        </a>
        <a
          href="https://www.gotts.com/private-events/st.-helena/"
          target="_blank"
        >
          <div className="timeline-block">
            <div
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${
                  isMobile ? 0.75 : 0
                })), url('${Gotts}')`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="timelineImg"
            />
            <div className="timeline-content">
              <div className="timelineTitle">
                Gott's Roadside, St Helena{"                                 "}
              </div>
              <div className="tileDate">SATURDAY, JUNE 17</div>
              <div className="tileDate">1:00PM - 2:00PM</div>
            </div>
          </div>
        </a>
        <a href="https://www.vsattui.com/" target="_blank">
          <div className="timeline-block">
            <div
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${
                  isMobile ? 0.75 : 0
                })), url('${Vsattui}')`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="timelineImg"
            />
            <div className="timeline-content">
              <div className="timelineTitle">
                V. Sattui Winery, St Helena - $60 per person
              </div>
              <div className="tileDate">SATURDAY, JUNE 17</div>
              <div className="tileDate">2:30PM - 4:00PM</div>
            </div>
          </div>
        </a>
        <div className="timeline-block">
          <div
            className="timelineTitle"
            style={{ textAlign: "center", padding: 30, textTransform: "none" }}
          >
            Please let us know if you are interested in joining us for the above
            wine tastings by April 1st. Space is limited due to group size
            restrictions in Sonoma Winery.
          </div>
        </div>
      </div>
    </div>
  );
});

export default Weekend;
