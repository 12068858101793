import { forwardRef } from "react";
// import Cover4 from "./cover4.jpg";
import pic from "./SB_Engagement-42.jpg";
import picMobile from "./SB_Engagement-43.jpg";
import "./Hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Hero = forwardRef((props, ref) => {
  let isMobile = window.mobileCheck();
  return (
    <div
      ref={ref}
      className="slide"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${
          isMobile ? 0.3 : 0.5
        })), url('${isMobile ? picMobile : pic}')`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="overlay">
        <div className="heading">Bhavik & Sarah</div>
        <div className="date">JUNE 16, 2023</div>
        <div className="date">SONOMA VALLEY, CA</div>
      </div>
      <FontAwesomeIcon
        icon={faAngleDown}
        className="arrow"
        style={{ bottom: isMobile ? 150 : 100 }}
      />
    </div>
  );
});

export default Hero;
