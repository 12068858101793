import { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import Courtyard from "./courtyard.jpeg";
import MarriotNarrow from "./marriot.jpeg";
import Slider from "react-slick";
import "./Hotel.css";

const isMobile = window.mobileCheck();
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  arrows: true,
};
const Hotel = forwardRef((props, ref) => {
  if (isMobile) {
    return (
      <div className="slide hotel" ref={ref}>
        <span className="title">Accommodations</span>
        <Slider {...settings}>
          <div className="hotelCtn">
            <a
              href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1670871218954&key=GRP&app=resvlink"
              target="_blank"
            >
              <img
                src={isMobile ? MarriotNarrow : Courtyard}
                style={{ width: "100%" }}
              />
            </a>
            <div className="hotelRow">
              <div className="hotelInfo">
                <div className="tileTitle">
                  Courtyard by Marriott Petaluma Sonoma County
                </div>
                <div className="tileAddress">
                  <FontAwesomeIcon icon={faLocationDot} />
                  &nbsp;&nbsp;700 Caulfield Lane{"\n"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Petaluma CA, 94952
                </div>
                <div className="tileTime" style={{ marginTop: "5px" }}>
                  <FontAwesomeIcon icon={faPhone} />
                  &nbsp;<a href="tel:+17073475400">+1-707-347-5400</a>
                </div>
                <div className="tileTime">
                  <FontAwesomeIcon icon={faLink} />
                  &nbsp;
                  <a
                    href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1670871218954&key=GRP&app=resvlink"
                    target="_blank"
                  >
                    Courtyard Room Block
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="hotelCtn" style={{ marginLeft: 20 }}>
            <div className="hotelRow">
              <div className="info">
                <div className="tileTitle">Additional Information</div>
                <ul>
                  <li>
                    We have reserved a room block at the Courtyard by Marriott
                    Petaluma Sonoma County. Please make your reservations here:{" "}
                    <a
                      href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1670871218954&key=GRP&app=resvlink"
                      target="_blank"
                    >
                      Courtyard Room Block
                    </a>{" "}
                    by April 1, 2023
                  </li>
                  <li>
                    Rooms are limited, so please book early to guarantee a
                    discounted rate. Rates will be honored for 3 days prior and
                    3 days post wedding dates
                  </li>
                  <li>
                    We will be providing transportation to and from the wedding
                    venue. Please see the helpful information section below for
                    more details.
                  </li>
                  <li>
                    If you would prefer to stay at an Airbnb, please have a
                    designated driver who will be responsible for getting you
                    home after the wedding. We recommend staying in Sonoma, CA
                    as the nearby areas have windy roads and driving late at
                    night may be dangerous
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  } else {
    return (
      <div className="slide hotel" ref={ref}>
        <span className="title">Accommodations</span>
        <div className="hotelCtn">
          <a
            href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1670871218954&key=GRP&app=resvlink"
            target="_blank"
          >
            <img
              src={isMobile ? MarriotNarrow : Courtyard}
              style={{ width: "100%" }}
            />
          </a>
          <div className="hotelRow">
            <div className="hotelInfo">
              <div className="tileTitle">
                Courtyard by Marriott Petaluma Sonoma County
              </div>
              <div className="tileAddress">
                <FontAwesomeIcon icon={faLocationDot} />
                &nbsp;&nbsp;700 Caulfield Lane{"\n"}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Petaluma CA, 94952
              </div>
              <div className="tileTime">
                <FontAwesomeIcon icon={faLink} />
                &nbsp;
                <a
                  href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1670871218954&key=GRP&app=resvlink"
                  target="_blank"
                >
                  Courtyard Room Block
                </a>
              </div>
            </div>
            <div className="info">
              <div className="tileTitle">Additional Information</div>
              <ul>
                <li>
                  We have reserved a room block at the Courtyard by Marriott
                  Petaluma Sonoma County. Please make your reservations here:{" "}
                  <a
                    href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1670871218954&key=GRP&app=resvlink"
                    target="_blank"
                  >
                    Courtyard Room Block
                  </a>{" "}
                  by April 1, 2023
                </li>
                <li>
                  Rooms are limited, so please book early to guarantee a
                  discounted rate. Rates will be honored for 3 days prior and 3
                  days post wedding dates
                </li>

                <li>
                  If you would prefer to stay at an Airbnb, please have a
                  designated driver who will be responsible for getting you home
                  after the wedding. We recommend staying in Sonoma, CA as the
                  nearby areas have windy roads and driving late at night may be
                  dangerous
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default Hotel;
