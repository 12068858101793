import { forwardRef, useState } from "react";
import Modal, { closeStyle } from "simple-react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./RSVPModal.css";
var Airtable = require("airtable");
var base = new Airtable({
  apiKey:
    "patHY1YdbuDJlihqn.07818f2d8d86246f651cf2fea5cc28dd341a8eb0a698bedaeee439b9531c22b3",
}).base("appaCphmPPvwHa7NW");

const RSVPModal = ({
  person,
  showModal,
  closeModal,
  currScreen,
  setCurrScreen,
}) => {
  let name = person?.name;
  let wedding = person?.wedding;
  let mehndi = person?.mehndi;
  let haldi = person?.haldi;

  let invitedToWedding = wedding === "invited";
  let invitedToMehndi = mehndi === "invited";
  let invitedToHaldi = haldi === "invited";

  let [attendingHaldi, setattendingHaldi] = useState(false);
  let [attendingmehndi, setattendingmehndi] = useState(false);
  let [attendingWedding, setattendingWedding] = useState(false);
  let [entree, setEntree] = useState("");
  let [allergy, setAllergy] = useState("");

  const radioOnChange = (e) => {
    if (e.target.name === "haldi") {
      if (e.target.value === "yes") {
        setattendingHaldi(true);
      } else {
        setattendingHaldi(false);
      }
    } else if (e.target.name === "mehndi") {
      if (e.target.value === "yes") {
        setattendingmehndi(true);
      } else {
        setattendingmehndi(false);
      }
    } else if (e.target.name === "wedding") {
      if (e.target.value === "yes") {
        setattendingWedding(true);
      } else {
        setattendingWedding(false);
      }
    }
  };

  const onSubmit = () => {
    // console.log("attendingHaldi", attendingHaldi);
    // console.log("attendingmehndi", attendingmehndi);
    // console.log("attendingWedding", attendingWedding);
    // console.log("entree", entree);
    // console.log("allergy", allergy);
    setCurrScreen("4");

    base("Wedding RSVPs").create(
      [
        {
          fields: {
            Name: name,
            GrahShanti: attendingHaldi ? "Attending" : "Not-Attending",
            Mehdni: attendingmehndi ? "Attending" : "Not-Attending",
            Wedding: attendingWedding ? "Attending" : "Not-Attending",
            Entree: entree,
            Allergies: allergy,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.log(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });
        setCurrScreen("5");
      }
    );
  };

  const handleClose = () => {
    setattendingHaldi(false);
    setattendingmehndi(false);
    setattendingWedding(false);
    setEntree("");
    setAllergy("");
    closeModal();
  };

  if (currScreen === "1") {
    return (
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        containerClassName="modal"
        closeOnOuterClick={true}
        show={showModal}
        onClose={handleClose}
      >
        <div className="rowRight">
          <div onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </div>
        </div>
        {name ? (
          <>
            <span className="modalHeading">IS THIS YOU?</span>
            <span className="guestName">{person.name}</span>
            <button className="goldButton" onClick={() => setCurrScreen("2")}>
              YES
            </button>
          </>
        ) : (
          <span className="guestName">
            GUEST NOT FOUND. PLEASE CHECK THE SPELLING AND TRY AGAIN. OTHERWISE,
            PLEASE CONTACT BHAVIK OR SARAH.
          </span>
        )}
      </Modal>
    );
  } else if (currScreen === "2") {
    return (
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        containerClassName="modal"
        closeOnOuterClick={true}
        show={showModal}
        onClose={handleClose}
      >
        <div className="rowRight">
          <div onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </div>
        </div>
        <span className="tileTitle">You are invited to:</span>
        {invitedToHaldi && (
          <div className="eventCtn">
            <span className="eventTitle">
              Grah Shanti & Haldi - June 10, 2023
            </span>
            <div className="eventAddress">
              54 Thurmont Road{"\n"}Denville, NJ 07834
            </div>
            <div className="row">
              ATTENDING:
              <button
                onClick={radioOnChange}
                type="radio"
                name="haldi"
                value="yes"
                className={
                  attendingHaldi === true
                    ? "blackButton selected"
                    : "blackButton"
                }
              >
                YES
              </button>
              <button
                onClick={radioOnChange}
                type="radio"
                name="haldi"
                value="no"
                className={
                  attendingHaldi === false
                    ? "blackButton selected"
                    : "blackButton"
                }
              >
                NO
              </button>
            </div>
          </div>
        )}
        {invitedToMehndi && (
          <div className="eventCtn">
            <span className="eventTitle">Mehndi - June 13, 2023</span>
            <div className="eventAddress">
              54 Thurmont Road{"\n"}Denville, NJ 07834
            </div>
            <div className="row">
              ATTENDING:
              <button
                onClick={radioOnChange}
                type="radio"
                name="mehndi"
                value="yes"
                className={
                  attendingmehndi === true
                    ? "blackButton selected"
                    : "blackButton"
                }
              >
                YES
              </button>
              <button
                onClick={radioOnChange}
                type="radio"
                name="mehndi"
                value="no"
                className={
                  attendingmehndi === false
                    ? "blackButton selected"
                    : "blackButton"
                }
              >
                NO
              </button>
            </div>
          </div>
        )}
        {invitedToWedding && (
          <div className="eventCtn">
            <span className="eventTitle">
              Wedding and Reception - June 16, 2023
            </span>
            <div className="eventAddress">
              Viansa Sonoma Winery{"\n"} 25200 Arnold Dr, Sonoma, CA
            </div>
            <div className="row">
              ATTENDING:
              <button
                onClick={radioOnChange}
                type="radio"
                name="wedding"
                value="yes"
                className={
                  attendingWedding === true
                    ? "blackButton selected"
                    : "blackButton"
                }
              >
                YES
              </button>
              <button
                onClick={radioOnChange}
                type="radio"
                name="wedding"
                value="no"
                className={
                  attendingWedding === false
                    ? "blackButton selected"
                    : "blackButton"
                }
              >
                NO
              </button>
            </div>
          </div>
        )}
        <button
          className="goldButton"
          style={{ marginTop: 10 }}
          onClick={() => {
            if (attendingWedding) {
              setCurrScreen("3");
            } else {
              onSubmit();
            }
          }}
        >
          {attendingWedding ? "NEXT" : "SUBMIT RSVP"}
        </button>
      </Modal>
    );
  } else if (currScreen === "3") {
    return (
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        containerClassName="modal"
        closeOnOuterClick={true}
        show={showModal}
        onClose={handleClose}
      >
        <div className="rowRight">
          <div onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </div>
        </div>
        <div className="eventCtn entree">
          <span className="tileTitle">Reception Entree Selection</span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ padding: 10, marginTop: 20 }}>
              <button
                className={
                  entree === "steak" ? "blackButton selected" : "blackButton"
                }
                onClick={() => {
                  setEntree("steak");
                }}
              >
                Grilled Flank Steak (toasted sunflower sofrito, sungold tomato
                vinaigrette)
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <button
                className={
                  entree === "cod" ? "blackButton selected" : "blackButton"
                }
                onClick={() => {
                  setEntree("cod");
                }}
              >
                Pan-Seared Black Cod (shiso ginger glaze, scallion butter sauce)
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <button
                className={
                  entree === "ravioli" ? "blackButton selected" : "blackButton"
                }
                onClick={() => {
                  setEntree("ravioli");
                }}
              >
                Ricotta ravioli (tomato sugo, housemade buttermilk ricotta,
                basil, marjoram, parmesan)
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <button
                className={
                  entree === "glutenfree"
                    ? "blackButton selected"
                    : "blackButton"
                }
                onClick={() => {
                  setEntree("glutenfree");
                }}
              >
                Gluten Free
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <button
                className={
                  entree === "vegan" ? "blackButton selected" : "blackButton"
                }
                onClick={() => {
                  setEntree("vegan");
                }}
              >
                Vegan
              </button>
            </div>
            <div style={{ padding: 10 }}>
              <input
                placeholder="Any allergies or dietary restrictions?"
                value={allergy}
                style={{ width: "100%", boxSizing: "border-box", fontSize: 18 }}
                onChange={(e) => {
                  setAllergy(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <button
          className="goldButton"
          style={{ marginTop: 20 }}
          onClick={() => setCurrScreen("2")}
        >
          BACK
        </button>
        <button
          className="goldButton"
          style={{ marginTop: 10 }}
          onClick={onSubmit}
          disabled={entree == ""}
        >
          SUBMIT RSVP
        </button>
      </Modal>
    );
  } else if (currScreen === "4") {
    return (
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        containerClassName="modal"
        closeOnOuterClick={true}
        show={showModal}
        onClose={handleClose}
      >
        <span className="tileTitle">Submitting...</span>
        <svg
          width="24"
          height="24"
          stroke="#000"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "0 auto" }}
        >
          <g className="spinner_V8m1">
            <circle
              cx="12"
              cy="12"
              r="9.5"
              fill="none"
              strokeWidth="3"
            ></circle>
          </g>
        </svg>
      </Modal>
    );
  } else if (currScreen === "5") {
    return (
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        containerClassName="modal"
        closeOnOuterClick={true}
        show={showModal}
        onClose={handleClose}
      >
        <div className="rowRight">
          <div onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </div>
        </div>
        <span className="tileTitle">
          {attendingHaldi || attendingWedding || attendingmehndi
            ? "Thank you! We are so excited to celebrate with you soon!"
            : "Thank you for letting us know. We will miss you!"}
        </span>
        {(attendingHaldi || attendingWedding || attendingmehndi) &&
          "ADD TO CALENDAR:"}
        {attendingHaldi && (
          <div style={{ padding: 10 }}>
            <a href="/BhavikSarahGrahShanti.ics" download>
              <button className="blackButton">Grah Shanti & Haldi</button>
            </a>
          </div>
        )}
        {attendingmehndi && (
          <div style={{ padding: 10 }}>
            <a href="/BhavikSarahMehndi.ics" download>
              <button className="blackButton">Mehndi Henna Night</button>
            </a>
          </div>
        )}
        {attendingWedding && (
          <div style={{ padding: 10 }}>
            <a href="/BhavikSarahWedding.ics" download>
              <button className="blackButton">Wedding & Reception</button>
            </a>
          </div>
        )}
      </Modal>
    );
  } else {
    return null;
  }
};

export default RSVPModal;
