import { useRef } from "react";
import Hero from "./Hero/Hero";
import "./App.css";
import OurStory from "./OurStory/OurStory";
import Friends from "./Friends/Friends";
import Details from "./Details/Details";
import Hotel from "./Hotel/Hotel";
import RSVP from "./RSVP/RSVP";
import Weekend from "./Weekend/Weekend";
import Sidebar from "./Sidebar/Sidebar";
import PhotoGallery from "./PhotoGallery/PhotoGallery";
import FAQ from "./FAQ/FAQ";
import Cover4 from "./Hero/cover2.jpg";

function App() {
  const heroRef = useRef(null);
  const storyRef = useRef(null);
  const friendsRef = useRef(null);
  const detailsRef = useRef(null);
  const hotelRef = useRef(null);
  const weekendRef = useRef(null);
  const rsvpRef = useRef(null);
  const galleryRef = useRef(null);
  const faqRef = useRef(null);
  let isMobile = window.mobileCheck();
  // return (
  //   <div
  //     className="main"
  //     style={{
  //       height: "100vh",
  //       backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${Cover4}')`,
  //       backgroundPosition: "center center",
  //       backgroundSize: "cover",
  //       backgroundRepeat: "no-repeat",
  //     }}
  //   >
  //     <div className="overlay" style={{ display: "flex" }}>
  //       <div className="heading">Bhavik & Sarah</div>
  //       <div className="date">JUNE 16, 2023</div>
  //       <div className="date">SONOMA VALLEY, CA</div>
  //     </div>
  //   </div>
  // );
  return (
    <div className="main">
      {isMobile && (
        <Sidebar
          heroRef={heroRef}
          storyRef={storyRef}
          friendsRef={friendsRef}
          detailsRef={detailsRef}
          hotelRef={hotelRef}
          weekendRef={weekendRef}
          rsvpRef={rsvpRef}
          galleryRef={galleryRef}
          faqRef={faqRef}
        />
      )}

      <section className="scrollCtn">
        <Hero ref={heroRef} />
        {/* <OurStory ref={storyRef} />
        <Friends ref={friendsRef} /> */}
        <Details ref={detailsRef} />
        <Hotel ref={hotelRef} />
        <RSVP ref={rsvpRef} />
        <Weekend ref={weekendRef} />
        <FAQ ref={faqRef} />
        {/* <PhotoGallery ref={galleryRef} /> */}
      </section>
    </div>
  );
}

export default App;
