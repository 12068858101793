import { forwardRef, useState } from "react";
import Fuse from "fuse.js";
import Guests from "./guests.json";
import Modal, { closeStyle } from "simple-react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./RSVP.css";
import pic from "./SB_Engagement-149.jpg";
import RSVPModal from "../RSVPModal/RSVPModal";

const RSVP = forwardRef((props, ref) => {
  const options = {
    keys: ["name"],
  };
  const fuse = new Fuse(Guests, options);

  let [name, setName] = useState("");
  let [person, setPerson] = useState(undefined);
  let [personResults, setPersonResults] = useState(undefined);
  let [showModal, setShowModal] = useState(false);
  let [currScreen, setCurrScreen] = useState("1");

  const handleInput = (e) => {
    setName(e.target.value);
    let results = fuse.search(e.target.value);
    let personMatches = results.map((person) => person.item);
    setPersonResults(personMatches);
    setPerson(personMatches[0]);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (name != "") {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrScreen("1");
    setName("");
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  let isMobile = window.mobileCheck();

  if (isMobile) {
    return (
      <div
        className="slide rsvp"
        ref={ref}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${
            isMobile ? 0.5 : 0
          })), url('${pic}')`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div style={{ width: isMobile ? "100%" : "60%", margin: "0 auto" }}>
          <span className="title" style={{ letterSpacing: 1 }}>
            Rsvp
          </span>
          <div className="rsvpCtn">
            <h2>PLEASE ENTER THE NAME ON YOUR INVITATION</h2>
            {/* <div
              style={{
                fontSize: 18,
                color: "#666666",
                marginBottom: 20,
                lineHeight: "12px",
              }}
            >
              <center>RSVP BY MARCH 1, 2023</center>
            </div> */}
            <div className="row">
              <input
                type="text"
                value={name}
                onChange={handleInput}
                onKeyPress={handleEnter}
                onBlur={() => {
                  ref.current?.scrollIntoView({ behavior: "smooth" });
                }}
              />
              <button
                className="blackButton"
                style={{ marginLeft: 10 }}
                onClick={handleSearch}
              >
                FIND RSVP
              </button>
            </div>
            <div
              style={{
                fontSize: 12,
                color: "#666666",
                marginTop: 20,
                lineHeight: "12px",
              }}
            >
              <i>
                <center>
                  We kindly request no boxed gifts.
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://www.zola.com/registry/bhavikandsarah"
                  >
                    View Our Wedding Registry
                  </a>
                </center>
              </i>
            </div>
          </div>
          <RSVPModal
            person={person}
            showModal={showModal}
            closeModal={closeModal}
            currScreen={currScreen}
            setCurrScreen={setCurrScreen}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="slide rsvp"
        ref={ref}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${
            isMobile ? 0.5 : 0
          })), url('${pic}')`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div style={{ width: isMobile ? "100%" : "60%", margin: "0 auto" }}>
          <span className="title" style={{ letterSpacing: 1 }}>
            Rsvp
          </span>
          <div className="rsvpCtn">
            <h2>PLEASE ENTER THE NAME ON YOUR INVITATION</h2>
            {/* <div
              style={{
                fontSize: 18,
                color: "#666666",
                marginBottom: 20,
                lineHeight: "12px",
              }}
            >
              <center>RSVP BY MARCH 1, 2023</center>
            </div> */}
            <div className="row">
              <input
                type="text"
                value={name}
                onChange={handleInput}
                onKeyPress={handleEnter}
              />
              <button
                className="blackButton"
                style={{ marginLeft: 10 }}
                onClick={handleSearch}
              >
                FIND RSVP
              </button>
            </div>
            <div
              style={{
                fontSize: 12,
                color: "#666666",
                marginTop: 20,
                lineHeight: "12px",
              }}
            >
              <i>
                <center>
                  We kindly request no boxed gifts.
                  <br />
                  <br />
                  <a
                    target="_blank"
                    href="https://www.zola.com/registry/bhavikandsarah"
                  >
                    View Our Wedding Registry
                  </a>
                </center>
              </i>
            </div>
          </div>
          <RSVPModal
            person={person}
            showModal={showModal}
            closeModal={closeModal}
            currScreen={currScreen}
            setCurrScreen={setCurrScreen}
          />
        </div>
      </div>
    );
  }
});

export default RSVP;
