import { forwardRef } from "react";
import Viansa from "./viansa.jpeg";
import ViansaWide from "./viansaWide.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import "./Details.css";

let isMobile = window.mobileCheck();

const Details = forwardRef((props, ref) => {
  if (isMobile) {
    return (
      <div className="slide details" ref={ref}>
        <span className="title">Where & When</span>
        <div className="tile">
          <div className="tileImage">
            <img src={Viansa} />
          </div>
          <div className="tileBody">
            <div className="tileTitle">WEDDING CEREMONY & RECEPTION</div>
            <div>
              <FontAwesomeIcon icon={faClock} />
              &nbsp;&nbsp;4:00PM - 12:00AM
            </div>
            <div>
              <FontAwesomeIcon icon={faCalendarDays} />
              &nbsp; June 16, 2023
            </div>
            <div className="tileAddress">
              <a href="https://goo.gl/maps/AajfspoiHrVG1A6L9" target="_blank">
                <FontAwesomeIcon icon={faLocationDot} />
                &nbsp;&nbsp;Viansa Sonoma Winery
                {"\n"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25200 Arnold Drive
                {"\n"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sonoma, CA
              </a>
            </div>
          </div>
          <div className="tileBody" style={{ marginBottom: "20px" }}>
            <div className="tileTitle">WEDDING SCHEDULE</div>
            <div className="tileAddress schedule">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:00PM | GUEST ARRIVAL
            </div>
            <div className="tileAddress schedule">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:30PM | HINDU WEDDING CEREMONY
            </div>
            <div className="tileAddress schedule">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5:30PM | COCKTAIL HOUR
            </div>
            <div className="tileAddress schedule">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6:45PM | KOREAN PAEBAEK CEREMONY
            </div>
            <div className="tileAddress schedule">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7:30PM | DINNER & DANCING
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="slide details" ref={ref}>
        <span className="title">Where & When</span>
        <div className="tileCtn">
          <div className="tile">
            <div className="tileImage">
              <img src={ViansaWide} />
            </div>
            <div style={{ display: "flex" }}>
              <div className="tileBody">
                <div className="tileTitle">WEDDING CEREMONY & RECEPTION</div>
                <div>
                  <FontAwesomeIcon icon={faClock} />
                  &nbsp;4:00PM - 12:00AM
                </div>
                <div>
                  <FontAwesomeIcon icon={faCalendarDays} />
                  &nbsp;&nbsp;June 16, 2023
                </div>
                <div className="tileAddress">
                  <a
                    href="https://goo.gl/maps/AajfspoiHrVG1A6L9"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    &nbsp;&nbsp;Viansa Sonoma Winery
                    {"\n"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25200 Arnold Drive
                    {"\n"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sonoma, CA
                  </a>
                </div>
              </div>
              <div className="tileBody schedule">
                <div className="tileTitle">WEDDING SCHEDULE</div>
                <div className="tileAddress schedule">
                  4:00PM | GUEST ARRIVAL
                </div>
                <div className="tileAddress">
                  4:30PM | HINDU WEDDING CEREMONY
                </div>
                <div className="tileAddress">5:30PM | COCKTAIL HOUR</div>
                <div className="tileAddress">
                  6:45PM | KOREAN PAEKBAEK CEREMONY
                </div>
                <div className="tileAddress">7:30PM | DINNER & DANCING</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default Details;
